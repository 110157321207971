// extracted by mini-css-extract-plugin
export var structure = "CourseStructure-module--structure--t9kVq";
export var structure__oval_headers = "CourseStructure-module--structure__oval_headers--BqL96";
export var structure__conjunction = "CourseStructure-module--structure__conjunction--3rV2f";
export var structure__list = "CourseStructure-module--structure__list--QtDiw";
export var structure__list_point = "CourseStructure-module--structure__list_point--KRu8k";
export var structure__a_square_l = "CourseStructure-module--structure__a_square_l--2_1H4";
export var structure__square_root_r = "CourseStructure-module--structure__square_root_r--8UGqz";
export var structure__header = "CourseStructure-module--structure__header--1Ubj8";
export var blob_positionStructure_left = "CourseStructure-module--blob_position-structure_left--3A_VG";
export var blob_positionStructure_right = "CourseStructure-module--blob_position-structure_right--2v6CU";