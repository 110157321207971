// extracted by mini-css-extract-plugin
export var blob = "Blob-module--blob--3vsTD";
export var light_green = "Blob-module--light_green--fxSlp";
export var violet = "Blob-module--violet--oUaYc";
export var light_violet = "Blob-module--light_violet--3x0SA";
export var blob_position_offer_left = "Blob-module--blob_position_offer_left--8Ee_S";
export var blob_position_offer_right = "Blob-module--blob_position_offer_right--2Ykvn";
export var blob_position_structure_left = "Blob-module--blob_position_structure_left--z_5G0";
export var blob_position_structure_right = "Blob-module--blob_position_structure_right--1JKyo";
export var blob_position_elementary = "Blob-module--blob_position_elementary--27cG5";
export var blob_position_finals = "Blob-module--blob_position_finals--1X4xo";
export var blob_position_contest1 = "Blob-module--blob_position_contest1--3I-5n";
export var blob_position_contest2 = "Blob-module--blob_position_contest2--1Cdyq";
export var blob_position_current_material = "Blob-module--blob_position_current_material--3oefa";